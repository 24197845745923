<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Determine the relative polarity of the following solvent systems for chromatography.
      </p>

      <drag-to-rank-input
        v-model="inputs.relativePolarities"
        :items="items"
        class="mb-0"
        style="max-width: 430px"
        prepend-text="Most Polar"
        append-text="Least Polar"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import DragToRankInput from '@/tasks/components/inputs/DragToRankInput.vue';

export default {
  name: 'uOttawa1321Prelab1Q5',
  components: {
    DragToRankInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        relativePolarities: [],
      },
      items: [
        'Methanol',
        '25% (v/v) ethyl acetate in hexanes',
        'Dichloromethane',
        '25% (v/v) ether in benzene',
      ],
    };
  },
};
</script>
